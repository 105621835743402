import store from '../store'
import router from '.';
import { permissions } from '@/modules/movida/auth/store/getters';

export default async (to, from, next) => {

  // Verifica se a rota possui o meta "logged" e se o valor é false
  if (to.meta.logged === false) {
    next()
    return false
  }

  if (to.name !== 'login' && !store.getters['auth/hasToken']) {

    try {
      await store.dispatch('auth/ActionCheckToken')
      router.push({ path: to.path })
    } catch (err) {
      next({ name: 'login' })
    }
  } else {
    if (to.name === 'login' && store.getters['auth/hasToken']) {
      next({ name: 'home' })
    } else {
      if(Object.keys(to.meta).length != 0){
        let p = await JSON.parse(localStorage.permissions);
        let can_navigate = false;
        p.forEach(element => {
          if(element == to.meta.permission){
            can_navigate = true;
          }
        });
        if(can_navigate){
          next();
        }else{
          if (permissions.length == 0) {
            alert('Atenção: O usuário não possui permissão para acessar o sistema. Redirecionando para o login')
            store.dispatch('auth/ActionSignOut')
            next({ name: 'login' })
            return false;
          }
          next({path:'/'})
        }
      }else{
        next();
      }
    }
  }
}