import services from '@/modules/movida/http';
import * as types from './mutation-types';
import * as storage from '../storage';


export const ActionDoLogin = ({dispatch}, payload) => {

    return services.auth.app().then(res => {
        const request = {
            username: payload.username,
            password: payload.password,
            recaptcha: payload.recaptcha,
            access_token: res.data.access_token
        }
        return services.auth.login(request).then(res => {
            const data = res.body;
            if (data.status){
              dispatch('ActionSetToken',res.body.data.token)
            }
            return data;
        });
    });
}

export const ActionCheckToken = ({ dispatch, state }) => {
    if (state.token) {
      return Promise.resolve(state.token)
    }
  
    const token = storage.getLocalToken()
  
    if (!token) {
      return Promise.reject(new Error('Token Inválido'))
    }
  
    dispatch('ActionSetToken', token)
    return dispatch('ActionLoadSession')
}

export const ActionLoadSession = ({ dispatch }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await services.auth.loadSession()
        dispatch('ActionSetUser', user.data.data)
        dispatch('ActionSetPermissions', user.data.data.acessos)
        resolve()
      } catch (err) {
        dispatch('ActionSignOut')
        reject(err)
      }
    })
}

export const ActionSetUser = ({ commit }, payload) => {
    commit(types.SET_USER, payload)
}

export const ActionSetPermissions = ({ commit }, payload) => {
    storage.setPermissions(JSON.stringify(payload))
}

export const ActionSetToken = ({ commit }, payload) => {
    storage.setHeaderToken(payload)
    storage.setLocalToken(payload)
    commit(types.SET_TOKEN, payload)
}

export const ActionSetAppToken = ({commit}, payload) => {
    commit(types.SET_APP_TOKEN,payload)
}

export const ActionSignOut = ({ dispatch }) => {
    storage.setHeaderToken('')
    storage.deleteLocalToken()
    dispatch('ActionSetUser', {})
    dispatch('ActionSetToken', '')
  }