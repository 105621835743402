<template>
  <div class="home">
    <navbar-component></navbar-component>
    <div class="columns">
      <div class="movida-sidebar aside">
        <sidebar-component></sidebar-component>
      </div>
      <div class="column main container">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .movida-sidebar {
    width: 280px;
  }

  .main {
    padding: 40px;
    box-sizing: border-box;
  }
</style>

<script>
import { Component, Vue } from 'vue-property-decorator';
import SidebarComponent from '@/modules/movida/components/SidebarComponent.vue';
import NavbarComponent from '@/modules/movida/components/NavbarComponent.vue';

@Component({
  components: {
    SidebarComponent,
    NavbarComponent,
  },
})

export default class Layout extends Vue {}

</script>
