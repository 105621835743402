<template>
  <section>
    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="overlay"
      :right="right"
      v-model="open"
      mobile="hide"
      :can-cancel="canCacel"
    >
      <MenuItens />
    </b-sidebar>
    
  </section>
</template>

<script>
import { Component } from 'vue-property-decorator';

import MenuItens from '@/modules/movida/components/MenuItens.vue';

@Component({
  components: {
    MenuItens
  },
})

export default class SidebarComponent {
  data() {
    return {
      open: true,
      overlay: false,
      fullheight: true,
      fullwidth: false,
      right: false,
      canCacel: ['outside']
    };
  }

};
</script>


<style>

.menu{
  margin-top: 20px;
}

.menu-list a{
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: #666;
}

.menu .menu-label{
  padding-left: 20px;
  font-size: 0.7rem;
}

.menu-list a:hover{
  display: flex;
  align-items: center;
  color: #F47920;
}


.menu-list a.is-active {
  color: hsl(0deg, 0%, 29%);
  background-color: #FAFAFA;
}

.menu-list a.is-router-active {
    color: #F47920;
    background-color: #FFF0E2;
    border-left: 3px solid;
}

.b-sidebar .sidebar-content.is-light{
  background-color:#fafafa;
  margin-top: 60px;
  padding-bottom: 60px;
}

.b-sidebar .sidebar-content{
  box-shadow: none;
  border-right:1px solid #d4d4d4;
}

</style>

