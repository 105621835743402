import { routes as auth } from "../auth";
import { routes as agendamento } from "@/modules/agendamento";

export default [
  ...auth,
  {
    path: "/",
    name: "home",
    redirect: { name: "dashboard_home" },
    meta: { permission: "dashboard.home" },
  }, 
  ...agendamento
];
