import Vue from 'vue';
import VueResource from 'vue-resource';
import services from './services';
import 'bulma/css/bulma.css';

Vue.use(VueResource);

const http = Vue.http

http.options.root = process.env.VUE_APP_BFF_URL;

Object.keys(services).map(service => {
  services[service] = Vue.resource('',{}, services[service])
})
http.interceptors.push((request, next) => {
  const authToken = process.env.VUE_APP_BFF_TOKEN;
  request.headers.set('Authorization', `Bearer ${authToken}`);
  next();
});

// http.interceptors.push(function(request, next){
//   next(function (response) {
//     if (response.status === 401) {
//       window.location = '/?token=false'
//     }
//   })
// });

export { http }
export default services;